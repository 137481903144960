import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PhoneIcon, DevicePhoneMobileIcon } from '@heroicons/react/20/solid';

function Header({ formRef }) {

    const navigate = useNavigate(); // that hook is used to navigate to the home page if the header element is rendered on the page without submission form

    const estimator = useSelector(state => state.estimator); // used to update the phone number in the header
    const kiosk = useSelector(state => state.kiosk); // used not to display header if the app is in kiosk mode

    const scrolltoForm = (e) => {
        // Preventing the default behavior of the form submit (which is to refresh the page)
        e.preventDefault();
        if (formRef && formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            // redirect to the home page if the formRef is not available
            navigate('/', { replace: true });

        };

    };

    const PhoneButton = () => {
        return (
            <a
                href={"tel:" + estimator.phone}
                className="flex items-center justify-center sm:justify-start text-green-700 font-bold hover:text-green-800 block w-64 h-15 sm:w-60 w-full mx-auto"
            >
                <PhoneIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                <>
                    {estimator.phone === '1-888-920-6317'
                        ? 'Toll Free 1-888-920-6317'
                        : estimator.phone}
                </>
            </a>
        );
    };

    const SmsButton = () => {
        return (
            <a
                href={`sms:${estimator.sms}?body=${encodeURIComponent(
                    "please schedule estimate"
                )}`}
                className="flex items-center justify-center sm:justify-start text-green-700 font-bold hover:text-green-800 block w-64 h-20 sm:w-60 w-full mx-auto"
                aria-label={`Send a text message to ${estimator.sms}`}
            >
                <DevicePhoneMobileIcon
                    className="h-6 w-6 mr-2"
                    aria-hidden="true"
                />
                Text Us {estimator.sms}
            </a>
        );
    };

    return (
        <header className="bg-white">
            <div className="flex flex-col items-center justify-between px-4 py-4 sm:px-6 lg:px-8 sm:flex-row flex-wrap">
                {/* Logo Section */}
                <div className="mb-4 sm:mb-0 sm:flex-shrink-0">
                    <img
                        className="h-16 w-auto"
                        src={require('../assets/curb-ease-logo.jpg')}
                        alt="Curb-Ease Logo"
                    />
                </div>

                {/* Links and Button Section */}
                <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-8 items-center">
                    {/* Phone Link */}
                    {(!kiosk && estimator.sms) ? <PhoneButton /> : (<div></div>)}

                    {/* SMS Link */}
                    {(estimator.sms && !kiosk) ? <SmsButton /> : (!estimator.sms && !kiosk) ?
                        <PhoneButton /> : (<div></div>)}

                    {/* Estimate Button */}
                    <button
                        onClick={scrolltoForm}
                        className="mt-2 block w-64 h-15 sm:w-56 rounded-md bg-green-700 py-2 text-lg font-semibold text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2 mx-auto"
                    >
                        Get a Free Estimate
                    </button>
                </div>
            </div>
            {kiosk ? null :
                <div
                    className="relative flex flex-col items-center justify-end bg-cover bg-center bg-no-repeat h-64"
                    style={{ backgroundImage: `url(${require('../assets/bckground.jpg')})` }}
                >
                    <h1 className="text-white font-bold px-8 py-4 text-xl sm:text-2xl lg:text-3xl">
                        Extruded Concrete Curbing
                    </h1>
                    <p className="text-white font-bold px-8 pb-4 text-base sm:text-lg">
                        since 1996
                    </p>
                </div>}
        </header>
    );
}
export default Header;
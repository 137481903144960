import { CheckCircleIcon } from '@heroicons/react/20/solid'
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateAudience } from '../utils/Actions';

export default function Pricing({ formRef }) {

  const audience = useSelector(state => state.lead.audience);
  const estimator = useSelector(state => state.estimator);
  const kiosk = useSelector(state => state.kiosk);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tiers = [
    {
      name: 'Concrete Curbs',
      id: 'curb',
      href: '/curbing',
      price: { ft: estimator?.pricing?.curbs || 'N/A', unit: 'linear foot' },
      description: 'Includes preparation, installation, materials and first time sealing.',
      features: [
        'Pricing is for residential profiles 6x4"',
        'Installation typically takes one day',
        'Payment is due upon installation completion',
        'Projects less than 50ft may be subject to a minimum charge',
      ],
    },
    {
      name: 'Concrete Resurfacing',
      id: 'resurfacing',
      href: '/armor-crete',
      price: { ft: estimator?.pricing?.resurfacing || 'N/A', unit: 'square foot' },
      description: 'Includes concrete surface prep and cleaning, crack and patch repair, resurfacing and sealing.',
      features: [
        'Concrete resurfacing is a minimum of 3-4 sunny days process',
        'Even smaller projects take a several days to complete',
        'Payment is 50% deposit at time of install, balance due upon completion',
        'Projects less than 200 sq ft may be subject to a minimum charge'
      ],
    },
    {
      name: 'Synthetic Grass',
      id: 'grass',
      href: '/grass',
      price: { ft: estimator?.pricing?.grass || 'N/A', unit: 'square foot' },
      description: 'Includes the cost of the grass and installation.',
      features: [
        'Grass comes in 15ft rolls, the price may change depending on the site configuration',
        'Payment is 50% deposit prior to install, balance due upon completion',
      ],
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    let timeoutId;

        const resetTimer = () => {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                navigate('/curbing', { replace: true }); // Navigate to the /timeout page
            }, 300000); // 300,000 milliseconds = 5 minutes
        };

        const handleKeyPress = () => {
            resetTimer(); // Reset the timer on key press
        };

        if (kiosk) {
            resetTimer();
            window.addEventListener('keydown', handleKeyPress);
        };
    
    return () => { // cleanup once the component is unmounted
      dispatch(updateAudience({ ...audience, curbing: false, resurfacing: false, grass: false, golf: false }));
      if (timeoutId) clearTimeout(timeoutId);
      if (kiosk) window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);


  const scrolltoForm = (e, id) => {
    // Preventing the default behavior of the form submit (which is to refresh the page)
    e.preventDefault();
    switch (id) {
      case 'curb':
        dispatch(updateAudience({ ...audience, curbing: true, resurfacing: false, grass: false, golf: false }));
        break;
      case 'resurfacing':
        dispatch(updateAudience({ ...audience, resurfacing: true, curbing: false, grass: false, golf: false }));
        break;
      case 'grass':
        dispatch(updateAudience({ ...audience, grass: true, curbing: false, resurfacing: false, golf: false }));
        break;
      default:
        break;
    };

    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl sm:text-center">
          <h2 className="text-base/7 font-semibold text-green-700">Pricing</h2>
          <p className="mt-2 text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-balance sm:text-6xl">
            Pricing of {estimator.search}
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-pretty text-lg font-medium text-gray-600 sm:text-center sm:text-xl/8">
          Pricing is provided for budgetary purposes and may change. Please request a free, no-obligation estimate for your project.
        </p>
        <div className="mt-20 flow-root">
          <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
            {tiers.map((tier) => (
              <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                <h3 id={tier.id} className="text-base/7 font-semibold text-gray-900">
                  {tier.name}
                </h3>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span
                    className="text-5xl font-semibold tracking-tight text-gray-900">
                    {tier.price.ft}
                  </span>
                  <span
                    className="text-sm/6 font-semibold text-gray-600">
                    /{tier.price.unit}
                  </span>
                </p>
                <a
                  href={tier.href}
                  className="mt-3 text-sm/6 text-gray-500 hover:text-gray-600">
                  Learn more...
                </a>
                <button
                  onClick={(e) => scrolltoForm(e, tier.id)}
                  aria-describedby={tier.id}
                  className="mt-10 block w-full rounded-md bg-green-700 px-3 py-2 text-center text-sm/6 font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700"
                >
                  Get a Free Estimate
                </button>
                <p className="mt-10 text-sm/6 font-semibold text-gray-900">{tier.description}</p>
                <ul role="list" className="mt-6 space-y-3 text-sm/6 text-gray-600">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckCircleIcon aria-hidden="true" className="h-6 w-5 flex-none text-green-700" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateAudience } from '../utils/Actions';
import Armortech from '../components/Armortech';
import Armorcalltoaction from '../components/Armorcalltoaction';
import Virtualassistant from '../components/Virtualassistant';
import Armorvideotech from '../components/Armorvideotech';
import Submission from '../components/Submission';
import Reviews from '../components/Reviews';
import Armorportfolio from '../components/Armorportfolio';

function Armor({ formRef }) {

  const state = useSelector(state => state);
  const audience = useSelector(state => state.lead.audience);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => { // update the state when the component is mounted
    dispatch(updateAudience({ ...audience, resurfacing: true, curbing: false, golf: false, grass: false }));
    window.scrollTo(0, 0);
    let timeoutId;

    const resetTimer = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        navigate('/grass', { replace: true }); // Navigate to the /timeout page
      }, 300000); // 300,000 milliseconds = 5 minutes
    };

    const handleKeyPress = () => {
      resetTimer(); // Reset the timer on key press
    };

    if (state.kiosk) {
      resetTimer();
      window.addEventListener('keydown', handleKeyPress);
    };

    return () => { // cleanup once the component is unmounted
      dispatch(updateAudience({ ...audience, resurfacing: false }));
      if (timeoutId) clearTimeout(timeoutId);
      if (state.kiosk) window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (

    <form>
      <Armorvideotech formRef={formRef} />
      <Armortech />
      <Armorportfolio />
      <Submission formRef={formRef} />
      <Reviews />
      <Armorcalltoaction formRef={formRef} />
      <Virtualassistant theme={'resurfacing'} />
    </form>
  );
}

export default Armor;
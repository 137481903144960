import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    updateKiosk
} from '../utils/Actions';

const Kiosk = () => { // that component obtains the correct URL for Calendly in GTA and ensures Calendly after redirect

    const dispatch = useDispatch();
    const navigate = useNavigate(); // that hook is used to navigate to the home page after the state is updated

    useEffect(() => {
        const abc = async () => {
            dispatch(updateKiosk(true)); // update state to aware app is in kiosk mode
            navigate('/', { replace: true });
        };
        abc();
    });
    return null;
};

export default Kiosk;
import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './pages/Contact';
import Armor from './pages/Armor';
import Golf from './pages/Golf';
import Grass from './pages/Grass';
import Color from './pages/Color';
import Footer from './components/Footer';
import Header from './components/Header';
import Gta from './components/Gta';
import Kiosk from './components/Kiosk';
import Pricing from './pages/Pricing';
import Submission from './components/Submission';
import Privacy from './pages/Privacy';
import DecodeParams from './components/Decodeparams';
import PageNotFound from './pages/PageNotFound';
import Testimonials from './pages/Testimonials';
import './styles.css';
import Curbprofiles from './pages/Curbprofiles';
import { getKey } from './utils/helpers';
import { updateStateCached, updateEstimator } from './utils/Actions';
import { estimators } from './utils/State';

function App() {
  const formRef = useRef(null);
  const state = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchClientIp = async () => {
      try {
        // Make a POST request to fetch the client IP
        const response = await fetch('/api/client-ip', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': getKey() // this is for protection of the server route
          },
          body: JSON.stringify({ requestType: 'fetchIp' }), // Example payload
        });

        if (response.ok) {
          const data = await response.json();
          fetchIpLocation(data.clientIp);
        };
      } catch (error) {
        console.error('Error fetching client IP:', error);
      }
    };

    const fetchIpLocation = async (ip) => {
      try {
        // Make a POST request to fetch the client IP
        const response = await fetch('/api/decode-ip', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': getKey() // this is for protection of the server route
          },
          body: JSON.stringify({ clientIp: ip }),
        });

        if (response.ok) {
          const data = await response.json();

          switch (data.region.toLowerCase()) {
            case 'ontario':
              dispatch(updateEstimator(estimators[0])); // Vasily
              break;
            case 'alberta':
              dispatch(updateEstimator(estimators[5])); // David
              break;
            case 'british columbia':
              dispatch(updateEstimator(estimators[1])); // Garry
              break;
            default:
              if (data.country==='US') dispatch(updateEstimator(estimators[9])) // Mario and Paul
                else dispatch(updateEstimator(estimators[0])); // Vasily
              break;
          };         
        };
      } catch (error) {
        console.error('Error fetching IP location:', error);
      }
    };

    if (!state.cached) { // if state is not cached, update estimator based on client IP
      fetchClientIp(); // fetch the client IP and location
      dispatch(updateStateCached(true)); // changing the state to cached as it will be cached after the first time
    };

  }, []);



  return (
    <Router>
      <Routes>
        <Route
          path="/curbing"
          element={<><Header formRef={formRef} /><Contact formRef={formRef} /><Footer /></>}
        />
        <Route
          path="/prefill/:fill"
          element={<DecodeParams />}
        />
        <Route
          path="/gta"
          element={<Gta />}
        />
        <Route
          path="/kiosk"
          element={<Kiosk />}
        />
        <Route
          path="/"
          element={<><Header formRef={formRef} /><Pricing formRef={formRef} /><Submission formRef={formRef} /><Footer /></>}
        />
        <Route
          path="/golf"
          element={<><Header formRef={formRef} /><Golf formRef={formRef} /><Submission formRef={formRef} /><Footer /></>}
        />
        <Route
          path="/grass"
          element={<><Header formRef={formRef} /><Grass formRef={formRef} /><Submission formRef={formRef} /><Footer /></>}
        />
        <Route
          path="/color"
          element={<><Header formRef={formRef} /><Color /><Submission formRef={formRef} /><Footer /></>}
        />
        <Route
          path="/profiles"
          element={<><Header formRef={formRef} /><Curbprofiles formRef={formRef} /><Footer /></>}
        />
        <Route
          path="/armor-crete"
          element={<><Header formRef={formRef} /><Armor formRef={formRef} /><Footer /></>}
        />
        <Route
          path="/testimonials"
          element={<><Header formRef={formRef} /><Testimonials /><Submission formRef={formRef} /><Footer /></>}
        />
        <Route
          path="/privacy-policy"
          element={<><Header formRef={formRef} /><Privacy /><Submission formRef={formRef} /><Footer /></>}
        />
        <Route
          path='*'
          element={<PageNotFound />}
        />
      </Routes>
    </Router>
  );
}

export default App;
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateAudience } from '../utils/Actions';
import Technology from '../components/Technology';
import Calltoaction from '../components/Calltoaction';
import Virtualassistant from '../components/Virtualassistant';
import Videotech from '../components/VideoTech';
import Submission from '../components/Submission';
import Reviews from '../components/Reviews';
import Portfolio from '../components/Portfolio';

function Contact({ formRef }) {

  const kiosk = useSelector(state => state.kiosk);
  const audience = useSelector(state => state.lead.audience);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => { // update the state when the component is mounted

    dispatch(updateAudience({ ...audience, curbing: true, resurfacing: false, golf: false, grass: false }));
    window.scrollTo(0, 0);
    let timeoutId;

    const resetTimer = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        navigate('/profiles', { replace: true }); // Navigate to the /timeout page
      }, 300000); // 300,000 milliseconds = 5 minutes
    };

    const handleKeyPress = () => {
      resetTimer(); // Reset the timer on key press
    };

    if (kiosk) {
      resetTimer();
      window.addEventListener('keydown', handleKeyPress);
    };

    return () => { // cleanup once the component is unmounted
      dispatch(updateAudience({ ...audience, curbing: false }));
      if (timeoutId) clearTimeout(timeoutId);
      if (kiosk) window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <form>
      <Videotech formRef={formRef} />
      <Technology />
      <Portfolio />
      <Submission formRef={formRef} />
      <Reviews />
      <Calltoaction formRef={formRef} />
      <Virtualassistant theme={'curbing'} />
    </form>

  );
}

export default Contact;
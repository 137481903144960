import { initialState } from "./State";

export function reducer(state = initialState, action) {
    switch (action.type) {
        case 'updateMessage':
            return {
                ...state,
                message: action.payload
            };
        case 'updateStatus':
            return {
                ...state,
                status: action.payload
            };
        case 'updateLead':
            return {
                ...state,
                lead: action.payload
            };
        case 'updateEstimator':
            return {
                ...state,
                estimator: action.payload
            };
        case 'updateCalendly':
            return {
                ...state,
                calendly: action.payload
            };
        case 'updateCalendlyUrl':
            return {
                ...state,
                calendlyUrl: action.payload
            };
        case 'updateAudience':
            return {
                ...state,
                lead: {
                    ...state.lead,
                    audience: action.payload
                }
            };
        case 'updateAddress':
            return {
                ...state,
                lead: {
                    ...state.lead,
                    streetAddress: action.payload.streetAddress,
                    city: action.payload.city,
                    postalCode: action.payload.postalCode,
                    region: action.payload.region
                }
            };
        case 'updateCached':
            return {
                ...state,
                cached: action.payload
            };
        case 'updateKiosk':
            return {
                ...state,
                kiosk: action.payload
            };
        default:
            return state;
    }
};
export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
};

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validatePhoneNumber(phoneNumber) {
    const normalizedPhoneNumber = phoneNumber.replace(/[-()\s]/g, ''); // Remove hyphens, spaces, and brackets
    const re = /^\d{10}$/; // Match exactly 10 digits
    return re.test(normalizedPhoneNumber);
}

export function checkPassword(input) {
    const passw = /^[A-Za-z]\w{7,14}$/;
    return (input.match(passw) ? true : false);
}

let secretKey = "6Ldfn6UpAAAAABeliLdCyPr4pj6H7qelYPMwvvd6";

export function getKey() {
    // Obfuscated version of the secret key

    return secretKey;
}

export const getEnv = async (param) => { // get the calendly url from the backend
    try {
        const response = await fetch(
            '/api/url',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': getKey() // this is for protection of the server route
                },
                body: JSON.stringify({ value: param }),
            }
        );
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        return data.url;
    } catch (error) {
        console.error('Error fetching url:', error);
    }

};

export const getGeo = async (param) => { // get the geo location {lat, lon} from the address from the backend
    try {
        const response = await fetch(
            '/api/geolocation',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': getKey() // this is for protection of the server route
                },
                body: JSON.stringify({ value: param }),
            }
        );
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        return data; // {lat, lon}
    } catch (error) {
        console.error('Error fetching address geolocation:', error);
    }

};

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

export default function Color() {
    const kiosk = useSelector(state => state.kiosk);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => { // update the state when the component is mounted

        let timeoutId;

        const resetTimer = () => {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                navigate('/armor-crete', { replace: true }); // Navigate to the /timeout page
            }, 300000); // 300,000 milliseconds = 5 minutes
        };

        const handleKeyPress = () => {
            resetTimer(); // Reset the timer on key press
        };

        if (kiosk) {
            resetTimer();
            window.addEventListener('keydown', handleKeyPress);
        };
        return () => { // cleanup once the component is unmounted
            if (timeoutId) clearTimeout(timeoutId);
            if (kiosk) window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <>
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-left">

                        <h2 className="text-4xl font-semibold leading-7 text-green-700">Premium Integral Colors</h2>
                        <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                        Integral Forever. Vibrant Colors That Never Fade and Never Need Painting.
                        </p>

                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Curb-Ease® uses Curber's Choice® iron oxide pigments which infuse the concrete with rich, long-lasting, fade-resistant color.
                        </p>
                    </div>

                    <ul className="space-y-3">

                        <div>
                            <li className="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6">
                                <img
                                    src={require('../assets/colors/color-chart.jpg')}
                                    alt={'Curber\'s Choice® Premium Integral Colors'}
                                />
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </>

    )
}
